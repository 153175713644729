<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <Spinner :spin="spin"/>
        <section class="header ">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="goBack">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">相關推薦</span>
            <div class="flexH width right">
               
                            
            </div> 
        </section>
        <!-- Main -->
        <section class="main recommend uide-page-wrap gap-6">
            
            <div class="uide-recommend-board-wrap">
                
                <div class="uide-recommend-board">
                    <a @click.prevent="linkClickHandler(link.externalUrl)" target="_blank" class="uide-recommend-button-wrap" v-for="link in links" :key="link.id">
                        <div><img :src="link.imageUrl" class="rounded-lg" width="60"/></div>
                        <div class="uide-recommend-button-text">{{link.title}}</div>
                    </a>
                </div>
            </div>       



        </section>
        <section id="popLeave" class="popup">
            <div class="popBody">
                <a class="close" @click.prevent="closeLeaveModal"
                    ><font-awesome-icon
                        icon="fas fa-times"
                    ></font-awesome-icon
                ></a>
                <section class="popHeader">
                    <!-- <span class="fL">Title</span> -->
                </section>
                <section class="popMain">
                    <div class="fL text-center">提示</div>
                    <div class="icon">
                    </div>
                    <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
                </section>
                <section class="popFooter flex">
                    <a
                        class="button bg-transparent text-black border border-primary flex-1  submit rounded"
                        @click.prevent="closeLeaveModal"
                        >返回</a
                    >
                    <a
                        class="button flex-1 submit rounded"
                        @click.prevent="goOut()"
                        ><span class="fM">確認</span></a
                    >
                </section>
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import Spinner from '@/components/Spinner';
import moment from "moment-timezone";
import { mapState } from "vuex";



export default {
    name: "NotificationSetting",
    components: {
        Spinner
    },
    data() {
        return {
            data: [],
            spin: true,
            statusNames: {
                0: '快來搶獎勵',
                1: '已領取獎勵',
                2: '獎勵已搶完'
            },
            links: [],
            apiHost: process.env.VUE_APP_API_HOST,
            nextUrl: ''
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        this.getLinks();
        console.log("mounted!");
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('pageList', this.pageList);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        pageList() {

            let list = [
                {id:1,department:'局處名稱',ts:1720961906,title:'拉近你我距離的縣民 App，服務上線滿意度調查問卷',desc:"despcription",unread:true,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:30,reward_status:0},
                {id:2,department:'局處名稱',ts:1720961906,title:'城鄉政令宣導民眾滿意度調查，歡迎民眾踴躍填寫',desc:"despcription",unread:false,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:50,reward_status:1},
                {id:3,department:'局處名稱',ts:1720961906,title:'城鄉政令宣導民眾滿意度調查，歡迎民眾踴躍填寫2',desc:"despcription",unread:false,point:50,is_receive_award:true,reward_total:1500,reward_remain:600,reward_percentage:70,reward_status:2},

            ];            
            return Object.values(list).sort((a, b) => {
                return b.ts - a.ts;
            });
        }
    },
    methods: {
        linkClickHandler(url) {
            this.openLeaveModal();
            this.nextUrl = url
        },
        goOut() {
            this.closeLeaveModal()
            window.open(this.nextUrl, '_blank');
        },
        async getLinks() {
            try {
                const config = {
                    url: `${this.apiHost}/links/v1/merchants/yunlin/links`,
                    method: "get",
                }
                const res = await this.$http(config)
                this.links = res.data.links
            } catch (error) {
                console.error(error)   
            } finally {
                this.spin = false
            }
        },
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        }, 
        getStatusName(status) {
            return this.statusNames[status] || 'Unknown';
        },
        openLeaveModal() {
            const wrap = document.querySelector('#popLeave')
            wrap.style.display = 'flex'
        },
        closeLeaveModal() {
            const wrap = document.querySelector('#popLeave')
            wrap.style.display = 'none'
        }
    }
};
</script>
<style lang="scss" scoped>
.uide-recommend-board {
    display: grid;
    grid-template-columns:  repeat(3, minmax(0, 1fr));
    justify-items: center;
    row-gap: 1.5rem;
}
</style>
