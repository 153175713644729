<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header ">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                    <img  width="24" height="24" src="@/assets/icon/uide/x.svg" />
                </a>
            </div>
            <span class="fL nowrap">問卷調查</span>
            <div class="flexH width right">
               
                            
            </div> 
        </section>
        <!-- Main -->
        <section class="main bg-white">
            
            <div class=" flexV width padding mt-4 mb-4">
                <div
                    class="text-center flex gap-6 item-center py-4 flex-col"
                    v-if="(!pageData )  == true"                    
                >
                    <img  width="160" height="160" src="@/assets/icon/page-empty-content.svg" />
                   <div class="fL font-medium  cNeutral500 text-center">很抱歉，目前無可填寫問卷</div>
                </div>
                <div v-else  >
                    <div class="flex gap-4 flex-col rounded-20px solid bcNeutral100 shadow-1 ">
                        <img width="100%" src="@/assets/icon/uide/_b1-1-img.png" />
                        <div class="flex gap-3 flex-col px-4 py-4">
                            <div class="cNeutral900 text-2xl font-medium">{{ pageData.title }}</div>
                            <div class="cNeutral900">{{ pageData.activity_time }}</div>
                            <div class="cNeutral900 text-lg">
                                <div>{{ pageData.activity_desc }}</div>
                                <div>完成問卷填寫可獲得雲林幣 {{ pageData.point }} 枚</div>
                            </div>
                            <div class="cNeutral900 ">
                                點擊下方參加按鈕，即表示您確認已詳閱<a class="text-c01">隱私權政策</a>，並且同意<a class="text-c01">使用條款</a>。
                            </div>
                        </div>
                    
                    </div>
                    <a class="uide-button-1 mt-4">參加</a>
                </div>
              
               
                
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";



export default {
    name: "NotificationSetting",
    components: {
        
    },
    data() {
        return {
            data: [],
            statusNames: {
                0: '快來搶獎勵',
                1: '已領取獎勵',
                2: '獎勵已搶完'
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        console.log("mounted!");
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('pageData', this.pageData);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        pageData() {

            let pageData = {
                img:'/img.png',
                title:'拉近你我距離的縣民 App，服務上線滿意度調查問卷',
                activity_time : '調查時間：2024/06/05 ~ 2023/07/04',
                activity_desc : 'Lorem ipsum dolor sit amet consectetur. Scelerisque et vel nisl et massa nibh amet ut. Id volutpat leo lobortis dapibus. Sollicitudin pulvinar scelerisque posuere dolor.',
                point:5,
            };

            return pageData;
        }
    },
    methods: {
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        }, 
        getStatusName(status) {
            return this.statusNames[status] || 'Unknown';
        },
    }
};
</script>

